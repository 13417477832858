import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Email } from '../../assets/img/icons/common/Email.svg';

import { MainSidebarItem } from './Items/MainSidebarItem';

import './MainSidebar.scss';

export const MainSidebar = ({ login, isOpen, items, currentItem, setSidebar }) => {
  const history = useHistory();
  const [subitemsList, setSubitemsList] = useState(null);

  const sidebarRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', checkClickOutside);
    }
    return () => document.removeEventListener('click', checkClickOutside);
  }, [isOpen]);

  const checkClickOutside = (event) => {
    if (!(event.path || (event.composedPath && event.composedPath())).includes(sidebarRef.current)) {
      setSidebar(false);
      return document.removeEventListener('click', checkClickOutside);
    }
  };

  const handleLinkClick = (link) => {
    history.push(link);
    setSubitemsList(null);
    isOpen && setSidebar(false);
  };

  return (
    <div ref={sidebarRef} className={cx('main-sidebar__static-wrapper', { opened: isOpen })}>
      <aside className="main-sidebar__container">
        <div className="main-sidebar__container-scrollable" onScroll={() => setSubitemsList(null)}>
          <nav className="main-sidebar__nav">
            <ul className="main-sidebar__nav-list">
              {items.map((item, index) => (
                <MainSidebarItem
                  key={index}
                  Icon={item.Icon}
                  label={item.span}
                  // TODO: Check if this still necessary
                  tooltip={
                    item.tooltip && Array.isArray(item.tooltip)
                      ? item.tooltip.filter(
                          (value) => login.userType === 'ADMIN' || login.allowedPages.includes(value.link)
                        )
                      : null
                  }
                  active={currentItem === item.span}
                  disabled={item.disabled}
                  subitemsList={subitemsList}
                  timerRef={timerRef}
                  setSubitemsList={setSubitemsList}
                  onClick={() => handleLinkClick(item.link)}
                />
              ))}
            </ul>
          </nav>

          {isOpen && (
            <footer className="main-sidebar__footer">
              <a
                className="main-sidebar__footer__email"
                href="mailto:support@cfasmartrestaurant.com"
                target="_blank"
              >
                <Email />
                support@cfasmartrestaurant.com
              </a>
              <div className="main-sidebar__footer__copyright">
                © {new Date().getFullYear()} Chick-fil-A. <p>All rights reserved.</p>
              </div>
            </footer>
          )}
        </div>

        {subitemsList && (
          <ul
            className="main-sidebar__subitems-menu"
            style={{ top: `${subitemsList.top}px`, left: isOpen ? '254px' : '73px' }}
            onMouseOver={() => clearTimeout(timerRef.current)}
            onMouseLeave={() => {
              timerRef.current = setTimeout(() => {
                setSubitemsList(null);
              }, 500);
            }}
          >
            {subitemsList.tooltip.map((subitem, i) => (
              <li
                key={i}
                className="main-sidebar__subitems-menu__item"
                onClick={() => handleLinkClick(subitem.link)}
              >
                {subitem.span}
              </li>
            ))}
          </ul>
        )}
      </aside>
    </div>
  );
};
