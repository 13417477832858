import request from '../utils/request';

export function getRoles(params, signal) {
  return request({
    url: `/roles`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getRole(uuid, signal) {
  return request({
    url: `/roles/${uuid}`,
    method: 'get',
  });
}

export function createRole(params) {
  return request({
    url: `/roles`,
    method: 'post',
    data: params,
  });
}

export function updateRole(uuid, params) {
  return request({
    url: `/roles/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteRole(uuid) {
  return request({
    url: `/roles/${uuid}`,
    method: 'delete',
  });
}
