import * as types from '../actions/usersManageAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  sortBy: 'email',
  orderDirection: 'ASC',
  loading: false,
  search: '',
  status: null,
  type: null,
  restaurantUuid: null,
  roles: [],
  edit: {
    email: '',
    type: '',
    role: 'select',
    restaurant: 'select',
    notifications: false,
    password: '',
    multiFactorAuthentication: false,
  },
  data: [],
};

const usersManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_USERS_MANAGEMENT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_USERS_MANAGEMENT_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.CHANGE_USERS_MANAGEMENT_EDIT_VALUE:
      return {
        ...state,
        edit: {
          ...state.edit,
          [action.payload.key]: action.payload.value,
        },
      };

    case types.GET_USERS_MANAGEMENT:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_USERS_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.GET_USERS_MANAGEMENT_ERROR:
      return {
        ...state,
        loading: false,
        totalResults: 0,
        data: [],
      };

    case types.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.payload.map((item) => ({ label: item.role, value: item.uuid })),
      };

    case types.GET_USER_EDIT_MANAGEMENT_SUCCESS:
      return {
        ...state,
        edit: {
          email: action.payload.email,
          type: action.payload.type,
          role: action.payload.type !== 'ADMIN' ? action.payload.userRoleDto?.uuid : 'select',
          restaurant: action.payload.restaurantUuid,
          notifications: action.payload.notification,
          multiFactorAuthentication: action.payload.multiFactorAuthentication,
          password: action.payload.password,
        },
      };

    case types.RESET_USERS_MANAGEMENT_EDIT_STORE:
      return {
        ...state,
        edit: {
          ...initialState.edit,
        },
      };

    case types.RESET_USERS_MANAGEMENT_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default usersManageReducer;
