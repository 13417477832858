import { getPackages, createPackage, deletePackage, updatePackage } from '../../api/chickenPackages';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CHICKEN_PACKAGES_VALUE = 'CHANGE_CHICKEN_PACKAGES_VALUE';
export const CHANGE_MODAL_PACKAGES_VALUE = 'CHANGE_MODAL_PACKAGES_VALUE';
export const CHANGE_CHICKEN_PACKAGES_SEVERAL_VALUES = 'CHANGE_CHICKEN_PACKAGES_SEVERAL_VALUES';
export const RESET_CHICKEN_PACKAGES_STORE = 'RESET_CHICKEN_PACKAGES_STORE';

export const GET_CHICKEN_PACKAGES = 'GET_CHICKEN_PACKAGES';
export const GET_CHICKEN_PACKAGES_SUCCESS = 'GET_CHICKEN_PACKAGES_SUCCESS';
export const GET_CHICKEN_PACKAGES_ERROR = 'GET_CHICKEN_PACKAGES_ERROR';

export const NEW_CHICKEN_PACKAGE = 'NEW_CHICKEN_PACKAGE';
export const NEW_CHICKEN_PACKAGE_SUCCESS = 'NEW_CHICKEN_PACKAGE_SUCCESS';
export const NEW_CHICKEN_PACKAGE_ERROR = 'NEW_CHICKEN_PACKAGE_ERROR';

export const UPDATE_CHICKEN_PACKAGE = 'UPDATE_CHICKEN_PACKAGE';
export const UPDATE_CHICKEN_PACKAGE_SUCCESS = 'UPDATE_CHICKEN_PACKAGE_SUCCESS';
export const UPDATE_CHICKEN_PACKAGE_ERROR = 'UPDATE_CHICKEN_PACKAGE_ERROR';

export const DELETE_CHICKEN_PACKAGE = 'DELETE_CHICKEN_PACKAGE';
export const DELETE_CHICKEN_PACKAGE_SUCCESS = 'DELETE_CHICKEN_PACKAGE_SUCCESS';
export const DELETE_CHICKEN_PACKAGE_ERROR = 'DELETE_CHICKEN_PACKAGE_ERROR';

export const getPackagesAction = (function () {
  let prevController;

  return (params, actions) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CHICKEN_PACKAGES });
      try {
        const response = await getPackages(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess([...results.content]);
          dispatch({ type: GET_CHICKEN_PACKAGES_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CHICKEN_PACKAGES_ERROR });
        }
      }
    };
  };
})();

export const createPackageAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: NEW_CHICKEN_PACKAGE });
    try {
      const response = await createPackage(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: NEW_CHICKEN_PACKAGE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: NEW_CHICKEN_PACKAGE_ERROR });
    }
  };
};

export const updatePackageAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CHICKEN_PACKAGE });
    try {
      const response = await updatePackage(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_CHICKEN_PACKAGE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: UPDATE_CHICKEN_PACKAGE_ERROR });
    }
  };
};

export const deletePackageAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CHICKEN_PACKAGE });
    try {
      const response = await deletePackage(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_CHICKEN_PACKAGE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_CHICKEN_PACKAGE_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CHICKEN_PACKAGES_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CHICKEN_PACKAGES_SEVERAL_VALUES,
  payload: value,
});

export const changeModalValue = (key, value) => ({
  type: CHANGE_MODAL_PACKAGES_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_CHICKEN_PACKAGES_STORE,
});
