import cx from 'classnames';
import { BodyCell } from '../../Cells';

export const DesktopTableContent = ({ rows }) => {
  const handleClick = (e, cb) => {
    if (cb) {
      cb(e);
    }
  };

  return rows.map((row, index) => (
    <tr
      key={row.id || index}
      className={cx('custom-table__tbody-row', { selectable: row.onSelectClick, selected: row.selected })}
      style={{ height: row.minHeight || 'auto' }}
      onClick={(e) => handleClick(e, row.onSelectClick)}
    >
      {row.cells.map((cell, index) => (
        <BodyCell key={`${row.id}-${index}`} cell={cell} />
      ))}
    </tr>
  ));
};
