import React, { useMemo } from 'react';
import cx from 'classnames';

export const EstimateCell = ({ item, stages }) => {
  const cellStyle = useMemo(() => {
    const width = `calc(100% / ${stages.length})`;
    const left = `calc((100% / ${stages.length}) * (${item.stage} - 1))`;

    return { width, left };
  }, [item]);

  return (
    <div className="queue-table__estimate-cell" style={cellStyle}>
      <div
        className={cx('queue-table__estimate-cell__tag', {
          completed: item.completed,
          animated: item.animated,
        })}
      >
        {!!item.completionTime ? item.completionTime : item.expectedCompletionTime}
      </div>
    </div>
  );
};
