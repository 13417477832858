import React, { useMemo } from 'react';

import { ReactComponent as CheckOutlined } from '../../../assets/img/icons/common/CheckOutlined.svg';

import { getModuleLabel } from '../../../constants/modules.constants';

import { combinePolicies } from '../../../utils/permissions';

import './PermissionsList.scss';

export const PermissionsList = ({ policies }) => {
  const permissions = useMemo(() => {
    const combinedPolicies = combinePolicies(policies);
    const readablePolicies = combinedPolicies.map((policy) => ({
      moduleType: getModuleLabel(policy.moduleType),
      actionType: policy.actionType.map((action) => action.charAt(0) + action.slice(1).toLowerCase()),
    }));

    return readablePolicies;
  }, [policies]);

  return (
    <div className="permissions-list-container">
      {permissions.map((policy) => (
        <div key={policy.moduleType} className="permissions-item">
          <h6 className="permissions-item__title">{policy.moduleType}</h6>

          <div className="permissions-item__actions">
            {policy.actionType.map((actionType) => (
              <div key={actionType} className="permissions-item__action">
                <CheckOutlined />
                <span>{actionType}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
