import { getPolicies, createPolicy, updatePolicy, deletePolicy, getConstituents } from '../../api/policies';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_POLICIES_VALUE = 'CHANGE_POLICIES_VALUE';
export const CHANGE_POLICIES_SEVERAL_VALUES = 'CHANGE_POLICIES_SEVERAL_VALUES';

export const RESET_POLICIES_STORE = 'RESET_POLICIES_STORE';

export const GET_POLICIES = 'GET_POLICIES';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_ERROR = 'GET_POLICIES_ERROR';

export const CREATE_POLICY = 'CREATE_POLICY';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const CREATE_POLICY_ERROR = 'CREATE_POLICY_ERROR';

export const UPDATE_POLICY = 'UPDATE_POLICY';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const UPDATE_POLICY_ERROR = 'UPDATE_POLICY_ERROR';

export const DELETE_POLICY = 'DELETE_POLICY';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_ERROR = 'DELETE_POLICY_ERROR';

export const GET_CONSTITUENTS = 'GET_CONSTITUENTS';
export const GET_CONSTITUENTS_SUCCESS = 'GET_CONSTITUENTS_SUCCESS';
export const GET_CONSTITUENTS_ERROR = 'GET_CONSTITUENTS_ERROR';

export const getPoliciesAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_POLICIES });
      try {
        const response = await getPolicies(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess && actions.onSuccess(results);
          dispatch({ type: GET_POLICIES_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions.onError && actions.onError(error.message);
          dispatch({ type: GET_POLICIES_ERROR });
        }
      }
    };
  };
})();

export const getAllPoliciesAction = (function () {
  let prevController;

  return (actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        const response = await getPolicies({}, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getConstituentsAction = (actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_CONSTITUENTS });
    try {
      const response = await getConstituents();
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
        dispatch({ type: GET_CONSTITUENTS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.error(error);
      actions.onError && actions.onError(error.message);
      dispatch({ type: GET_CONSTITUENTS_ERROR });
    }
  };
};

export const createPolicyAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_POLICY });
    try {
      const response = await createPolicy(params);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: CREATE_POLICY_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: CREATE_POLICY_ERROR });
    }
  };
};

export const updatePolicyAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_POLICY });
    try {
      const response = await updatePolicy(uuid, params);

      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: UPDATE_POLICY_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: UPDATE_POLICY_ERROR });
    }
  };
};

export const deletePolicyAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_POLICY });
    try {
      const response = await deletePolicy(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: DELETE_POLICY_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: DELETE_POLICY_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_POLICIES_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_POLICIES_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_POLICIES_STORE,
});
