import { transformScreamingSnakeCaseIntoHumanCase } from '../utils';

export const actionTypeOptions = [
  {
    label: 'View',
    value: 'VIEW',
  },
  {
    label: 'Create',
    value: 'CREATE',
  },
  {
    label: 'Edit',
    value: 'EDIT',
  },
  {
    label: 'Delete',
    value: 'DELETE',
  },
];

export const actionTypeOrder = ['VIEW', 'CREATE', 'EDIT', 'DELETE'];

export const moduleLabels = {
  DASHBOARD: 'Dashboard',
  PCS: 'PCS',
  EVENTS: 'Events',
  TIMER_CYCLE_HISTORY: 'Timer Cycle History',
  STOCK_OUT: 'Stock Out',
  PERFORMANCE: 'Performance',
  UNUSUAL: 'Unusual',
  KITCHEN_PLAYER: 'Kitchen Player',
  PLUG_AND_PLAY: 'Plug And Play',
  CAMERAS: 'Cameras',
  DEVICE: 'Device',
  RESTAURANT: 'Restaurant',
  MONITORING: 'Monitoring',
  REPORTS: 'Reports',
  MATERIAL_SETTINGS: 'Material Settings',
  USERS: 'Users',
  AUDIT: 'Audit',
  SUPPORT: 'Support',
  KPS_SETTINGS: 'Kps Settings',
  PACKAGING: 'Packaging',
  SERVICE: 'Service',
  PRODUCTION: 'Production',
  TRANSACTIONS: 'Transactions',
  ORDERS: 'Orders',
  RECOMMENDATIONS: 'Recommendations',
  DLPS: 'DLPS',
  PINS: 'Pins',
  VTM_MESSAGES: 'Vtm Messages',
  LOGS: 'Logs',
};

export const getModuleLabel = (moduleType) =>
  moduleLabels[moduleType] || transformScreamingSnakeCaseIntoHumanCase(moduleType);
