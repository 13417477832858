import React from 'react';

import { FormCheckbox } from '../../FormFields';
import { ButtonLink } from '../../Buttons';

import { actionTypeOptions } from '../../../constants/modules.constants';

import './PermissionsField.scss';

export const PermissionsField = ({ selectedPermissions, search = '', moduleTypes, changeValue }) => {
  const handleSelectAll = () => {
    const allActionTypes = actionTypeOptions.map((action) => action.value);
    const allModuleTypes = moduleTypes.map((module) => module.value);

    changeValue(allModuleTypes.map((moduleType) => ({ moduleType, actionType: allActionTypes })));
  };

  const handleClearAll = () => {
    changeValue([]);
  };

  const handleChangeModuleValue = (module) => {
    const moduleType = module.value;
    const allActionTypes = actionTypeOptions.map((action) => action.value);

    const moduleExists = selectedPermissions.some((permission) => permission.moduleType === moduleType);

    if (moduleExists) {
      changeValue(selectedPermissions.filter((permission) => permission.moduleType !== moduleType));
    } else {
      changeValue([...selectedPermissions, { moduleType, actionType: allActionTypes }]);
    }
  };

  const handleChangeActionValue = (module, action) => {
    const moduleType = module.value;
    const actionType = action.value;

    const moduleExists = selectedPermissions.find((permission) => permission.moduleType === moduleType);

    if (moduleExists) {
      const newPermissions = selectedPermissions
        .map((permission) => {
          if (permission.moduleType === moduleType) {
            const actionExists = permission.actionType.includes(actionType);

            if (actionExists) {
              return {
                ...permission,
                actionType: permission.actionType.filter((action) => action !== actionType),
              };
            }

            return {
              ...permission,
              actionType: [...permission.actionType, actionType],
            };
          }

          return permission;
        })
        .filter((permission) => permission.actionType.length > 0);

      changeValue(newPermissions);
    } else {
      changeValue([...selectedPermissions, { moduleType, actionType: [actionType] }]);
    }
  };

  const displayedModuleTypes = moduleTypes.filter((module) =>
    module.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="permissions-field-container">
      <div className="permissions-field__tools">
        <ButtonLink handleClick={handleSelectAll}>Select All</ButtonLink>
        <ButtonLink handleClick={handleClearAll}>Clear All</ButtonLink>
      </div>

      {displayedModuleTypes.map((module) => (
        <div className="permissions-item">
          <FormCheckbox
            label={module.label}
            checked={selectedPermissions.some((permission) => permission.moduleType === module.value)}
            onChange={() => handleChangeModuleValue(module)}
            rounded
            bold
          />

          <div className="permissions-item__actions">
            {actionTypeOptions.map((action) => (
              <FormCheckbox
                label={action.label}
                checked={selectedPermissions.some(
                  (permission) =>
                    permission.moduleType === module.value && permission.actionType.includes(action.value)
                )}
                onChange={() => handleChangeActionValue(module, action)}
                rounded
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
