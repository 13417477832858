import { actionTypeOrder } from '../constants/modules.constants';
import { pagesByModules } from '../constants/sidebar.constants';

export const combinePolicies = (policies = []) => {
  const permissionsMap = {};

  policies.forEach((policy) => {
    policy.accesses.forEach((access) => {
      if (!permissionsMap[access.moduleType]) {
        permissionsMap[access.moduleType] = new Set();
      }
      access.actionType.forEach((action) => {
        permissionsMap[access.moduleType].add(action);
      });
    });
  });

  return Object.keys(permissionsMap).map((moduleType) => ({
    moduleType: moduleType,
    actionType: Array.from(permissionsMap[moduleType]).sort(
      (a, b) => actionTypeOrder.indexOf(a) - actionTypeOrder.indexOf(b)
    ),
  }));
};

export const getRolePermissions = (role) => {
  if (!role) return {};

  const combinedPolicies = combinePolicies(role.policies);
  const rolePermissions = combinedPolicies.reduce((acc, permission) => {
    acc[permission.moduleType] = {
      VIEW: permission.actionType.includes('VIEW'),
      CREATE: permission.actionType.includes('CREATE'),
      EDIT: permission.actionType.includes('EDIT'),
      DELETE: permission.actionType.includes('DELETE'),
    };
    return acc;
  }, {});

  return rolePermissions;
};

export const getAllowedPages = (role) => {
  if (!role) return [];

  const combinedPolicies = combinePolicies(role.policies);
  const viewModules = combinedPolicies
    .filter((permission) => permission.actionType.includes('VIEW'))
    .map((permission) => permission.moduleType);
  const allowedPages = pagesByModules
    .map(({ module, pages }) => (viewModules.includes(module) ? pages : []))
    .flat();
  const uniqueAllowedPages = [...new Set(allowedPages)];

  return uniqueAllowedPages;
};

export const filterSidebarItemsByAccess = (sidebarItems, allowedPages) => {
  return sidebarItems
    .map((sidebarItem) => {
      if (sidebarItem.tooltip) {
        const filteredTooltips = sidebarItem.tooltip.filter((tooltip) => allowedPages.includes(tooltip.link));
        if (filteredTooltips.length > 0) {
          return {
            ...sidebarItem,
            link: filteredTooltips[0].link,
            tooltip: filteredTooltips,
          };
        } else {
          return null;
        }
      } else if (allowedPages.includes(sidebarItem.link)) {
        return sidebarItem;
      } else {
        return null;
      }
    })
    .filter((page) => page);
};

export const hasAccessToPage = (userType, allowedPages, page) => {
  if (userType && (allowedPages.length !== 0 || userType === 'ADMIN')) {
    if (userType === 'ADMIN') return true;
    if (allowedPages.includes(page)) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};
