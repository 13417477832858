import React from 'react';

import cx from 'classnames';

import { ReactComponent as Delete } from '../../assets/img/icons/common/Delete.svg';
import { ReactComponent as Devices } from '../../assets/img/icons/common/Devices.svg';
import { ReactComponent as Edit } from '../../assets/img/icons/common/Edit.svg';

import { Preloader } from '../Preloader/Preloader';
import { TablePagination } from '../TablePagination/TablePagination';
import { EmptyContainer } from '../EmptyContainer';

import './IconTableCustom.scss';

export const IconTableRestaurant = ({
  rows,
  totalRows,
  currentPage,
  perPage,
  loading,
  changeValue,
  onDelete,
  onDevicesClick,
  onEdit,
}) => {
  return !loading ? (
    rows.length !== 0 ? (
      <>
        <div className="icon-view-restaurant__container">
          {rows.map((item, index) => (
            <div className={cx('restaurant-view__item', item.status.toLowerCase())} key={item.uuid || index}>
              <div className="restaurant-view__item-controls">
                <div
                  className={cx('restaurant-view__control restaurant-view__control-devices', {
                    disabled: !onDevicesClick,
                  })}
                  onClick={!!onDevicesClick ? () => onDevicesClick(item.uuid) : () => {}}
                >
                  <Devices />
                  {(item.devices?.length || 0) + (item.tablets?.length || 0)}
                </div>
                <div className="restaurant-view__item-controls__item">
                  {!!onEdit && (
                    <div className="restaurant-view__control" onClick={() => onEdit(item.uuid)}>
                      <Edit />
                    </div>
                  )}
                  {!!onDelete && (
                    <div className="restaurant-view__control" onClick={() => onDelete(item.name, item.uuid)}>
                      <Delete />
                    </div>
                  )}
                </div>
              </div>
              <div className="restaurant-view__text">
                <div className="restaurant-view__text-title">{item.number}</div>
                <span>{item.name}</span>
              </div>
            </div>
          ))}
        </div>
        <TablePagination
          isLoading={loading}
          className="icon-view__pagination"
          totalRows={totalRows}
          page={currentPage}
          perPage={perPage}
          siblingCount={1}
          onChangePage={(value) => changeValue('page', value)}
          onChangePerPage={(value) => changeValue('limit', value)}
        />
      </>
    ) : (
      <EmptyContainer />
    )
  ) : (
    <div className="icon-view__loader">
      <Preloader big={true} />
    </div>
  );
};
