import moment from 'moment';

import { ValueWithChange } from '../../components';

import {
  cateringStatusColors,
  cateringStatuses,
  cateringStatusValues,
} from '../../constants/catering.constants';
import {
  animationValues,
  cateringPCSStages,
  cateringStagesMap,
  emptyStage,
  prqStages,
  prqStagesMap,
  stageProgressValues,
  statusColors,
  statusValues,
} from '../../constants/productionQueue.constants';
import { getAccessToken } from '../../utils/authorization';
import { getTimeFromMS, getTimeFromMSExcludeHours } from '../../utils';
import { getSearchParameter } from '../../utils/urlFunctions';

const PCS_PREFIX = '/pcs';

export const createProductionQueueRows = (rows) => {
  return rows
    .map((item) => {
      const statusColor = statusColors.find((it) => it.value === item.status) || statusColors[3];

      const stagesEstimate = !!item.stagesEstimate
        ? item.stagesEstimate.map((estimate) => ({
            ...estimate,
            stage: prqStagesMap[estimate.stageName],
            completed:
              !!estimate.completionTime ||
              (estimate.stageName === item.stageName && item.stageProgress === stageProgressValues.COMPLETED),
            animated:
              item.stageProgress !== stageProgressValues.COMPLETED && estimate.stageName === item.stageName,
          }))
        : null;

      return {
        id: item.batchId,
        cells: [
          {
            label: item.batchNumber || item.batchNumber === 0 ? item.batchNumber.toString() : '',
            mobile: true,
          },
          {
            label: (
              <div className="queue-table__status">
                {item.material}
                {item.stockoutTime && (
                  <div className="queue-table__status-stockout">{`Stockout ${getTimeFromMSExcludeHours(
                    item.stockoutTime
                  )}`}</div>
                )}
                {item.catering && <div className="queue-table__status-catering">Catering</div>}
                {item.catering && item.demandDescription && (
                  <div className="queue-table__status-description">{item.demandDescription}</div>
                )}
                <div
                  className="queue-table__status-color"
                  style={{
                    backgroundColor: statusColor.color,
                  }}
                ></div>
              </div>
            ),
          },
          {
            label:
              item.minutesBeforeStockout || item.minutesBeforeStockout === 0
                ? item.minutesBeforeStockout.toString()
                : '',
          },
          {
            label: <ValueWithChange value={item.batchSize} changePercent={item.batchIncreasePercent} />,
          },
          {
            label: formatGrams(item.minuteDemand),
          },
          {
            label: item.delay && item.delay > 0 ? getTimeFromMS(item.delay * 1000) : getTimeFromMS(0),
            endOfTable: true,
          },
        ],
        status: prqStages.find((it) => it.name === item.stageName) || emptyStage,
        statusName: statusColor,
        isCompleted: item.stageProgress === stageProgressValues.COMPLETED,
        isCanceled: item.status === statusValues.CANCEL,
        animation: item.animation || animationValues.FLAT,
        taskDelay:
          item.animation === animationValues.DELAYED && item.delay
            ? getTimeFromMSExcludeHours(item.delay * 1000)
            : 0,
        stagesEstimate: stagesEstimate,
      };
    })
    .sort(function (a, b) {
      return b.statusName.id - a.statusName.id;
    });
};

export const createCateringQueueRows = (rows) => {
  return rows
    .map((item) => {
      const statusColor = statusColors.find((it) => it.value === item.status) || statusColors[3];

      const stagesEstimate = !!item.stagesEstimate
        ? item.stagesEstimate.map((estimate) => ({
            ...estimate,
            stage: cateringStagesMap[estimate.stageName],
            completed:
              !!estimate.completionTime ||
              (estimate.stageName === item.stageName && item.stageProgress === stageProgressValues.COMPLETED),
            animated:
              item.stageProgress !== stageProgressValues.COMPLETED && estimate.stageName === item.stageName,
          }))
        : null;

      return {
        id: item.uuid,
        cells: [
          {
            label: item.priority || item.priority === 0 ? item.priority.toString() : '',
            mobile: true,
          },
          {
            label: item.batchNumber || item.batchNumber === 0 ? item.batchNumber.toString() : '',
            mobile: true,
          },
          {
            label: (
              <div className="queue-table__status">
                {item.material}
                {item.catering && <div className="queue-table__status-catering">Catering</div>}
                {item.catering && item.demandDescription && (
                  <div className="queue-table__status-description">{item.demandDescription}</div>
                )}
                <div
                  className="queue-table__status-color"
                  style={{
                    backgroundColor: statusColor.color,
                  }}
                ></div>
              </div>
            ),
          },
          {
            label: item.batchSize,
          },
          {
            label: item.delay && item.delay > 0 ? getTimeFromMS(item.delay * 1000) : getTimeFromMS(0),
            endOfTable: true,
          },
        ],
        status: cateringPCSStages.find((it) => it.name === item.stageName) || emptyStage,
        statusName: statusColor,
        isCompleted: item.stageProgress === stageProgressValues.COMPLETED,
        isCanceled: item.status === statusValues.CANCEL,
        animation: item.animation || animationValues.FLAT,
        taskDelay:
          item.animation === animationValues.DELAYED && item.delay
            ? getTimeFromMSExcludeHours(item.delay * 1000)
            : 0,
        stagesEstimate: stagesEstimate,
      };
    })
    .sort(function (a, b) {
      return b.statusName.id - a.statusName.id;
    });
};

export const createFutureQueueRows = (rows, timezone) => {
  return rows
    .map((item, index) => {
      const statusColor =
        cateringStatuses.find((it) => it.label === item.cateringOrderStatus) || statusColors[2];
      return {
        id: index,
        cells: [
          {
            label: item.batchNumber || item.batchNumber === 0 ? item.batchNumber.toString() : '',
          },
          {
            label: (
              <div className="queue-table__status">
                {item.material}
                {item.catering && <div className="queue-table__status-catering">Catering</div>}
                {item.catering && item.demandDescription && (
                  <div className="queue-table__status-description">{item.demandDescription}</div>
                )}
                <div
                  className="queue-table__status-color"
                  style={{
                    backgroundColor: statusColor.color,
                  }}
                ></div>
              </div>
            ),
          },
          {
            label:
              item.scheduledStart && item.scheduledEnd
                ? moment(item.scheduledStart).tz(timezone).format('h:mm a') +
                  ' - ' +
                  moment(item.scheduledEnd).tz(timezone).format('h:mm a')
                : '',
          },
          {
            label: item.batchSize,
          },
          {
            label: item.spacing + ' min',
          },
          {
            label: (item.progress || 0) + '%',
            endOfTable: true,
          },
        ],
        status: statusColor,
        statusName: statusColor,
        isCompleted: item.cateringOrderStatus.toLowerCase() === 'ready',
      };
    })
    .sort(function (a, b) {
      return b.statusName.stage - a.statusName.stage;
    });
};

export const createCateringOrderRows = (rows, timezone) => {
  return rows
    .map((item, index) => {
      const statusColor = cateringStatusColors[item.status] || cateringStatusColors.NEW;
      return {
        id: index,
        cells: [
          {
            label: formatPieces(item.batchSize),
            endOfTable: true,
          },
        ],
        status: cateringStatusColors[item.status] || emptyStage,
        statusName: statusColor,
        isCompleted:
          item.status === cateringStatusValues.FINISHED ||
          item.status === cateringStatusValues.INCOMPLETE ||
          item.status === cateringStatusValues.CANCELED,
        readyTime: item.readyTime,
        innerTaskDelay: item.taskDelay ? getTimeFromMSExcludeHours(item.taskDelay) : 0,
      };
    })
    .sort(function (a, b) {
      return b.statusName.stage - a.statusName.stage;
    });
};

export const getProductionQueueBrokerUrl = (restaurantUuid) => {
  return `${process.env.REACT_APP_CFA_PROXY.replace(
    /^[a-z]*:/,
    'wss:'
  )}/device/api/v1/sockets/tablet?Authorization=${getAccessToken()}&restaurantUuid=${restaurantUuid}`;
};

export const switchPCSTab = (tab, history) => {
  if (history.location.pathname.includes(`${PCS_PREFIX}/${tab}`)) return;

  const transferableParams = ['restaurantUuid'];

  const searchParams = transferableParams.reduce((acc, param) => {
    const paramValue = getSearchParameter(param);

    if (paramValue) {
      const divider = acc !== '' ? '&' : '?';
      let fullSearchParam = `${param}=${paramValue}`;

      return acc + divider + fullSearchParam;
    }

    return acc;
  }, '');

  history.push(`${PCS_PREFIX}/${tab}` + searchParams);
};

export const formatGrams = (number) => {
  if (!number && number !== 0) return '';

  const unit = number === 1 ? 'gram' : 'grams';
  return `${number} ${unit}`;
};

export const formatPieces = (number) => {
  if (!number && number !== 0) return '';

  const unit = number === 1 ? 'Piece' : 'Pieces';
  return `${number} ${unit}`;
};

const prepareInventoryDataFromOldDTO = (data) => {
  return data.map((item) => ({
    material: item.material,
    catering: item.catering,
    containers: [
      ...item.panInfo.map((pan) => ({
        ...pan,
        name: pan.panName,
        quantity: pan.quantity,
        pieces: pan.pieces,
      })),
      {
        isTotal: true,
        name: 'TOTAL',
        quantity: item.total?.quantity || 0,
        pieces: item.total?.pieces || 0,
      },
    ],
  }));
};

const prepareInventoryDataFromNewDTO = (data) => {
  return data.map((item) => ({
    material: item.material,
    catering: item.catering,
    containers:
      item.containers?.items && item.containers.items.length > 0
        ? [
            ...item.containers.items.map((pan) => ({
              ...pan,
              name: pan.panName,
              quantity: pan.quantity,
              pieces: pan.pieces,
            })),
            {
              isTotal: true,
              name: 'TOTAL',
              quantity: item.containers.total?.quantity || 0,
              pieces: item.containers.total?.pieces || 0,
            },
          ]
        : [],
    packages:
      item.packages?.items && item.packages.items.length > 0
        ? [
            ...item.packages.items.map((pan) => ({
              ...pan,
              name: pan.packageType,
              quantity: parseFloat(pan.quantity.toFixed(2)),
              pieces: pan.pieces,
            })),
            {
              isTotal: true,
              name: 'TOTAL',
              quantity: item.packages.total?.quantity || 0,
              pieces: item.packages.total?.pieces || 0,
            },
          ]
        : [],
    total:
      item.containers?.items &&
      item.containers.items.length > 0 &&
      item.packages?.items &&
      item.packages.items.length > 0
        ? [
            {
              isTotal: true,
              name: '',
              quantity: item.total.quantity,
              pieces: item.total.pieces,
            },
          ]
        : [],
  }));
};

export const prepareInventoryData = (data) => {
  data.sort((a, b) => {
    if (a.catering === b.catering) return 0;
    return a.catering ? 1 : -1;
  });

  if (data && data.length > 0 && data[0].panInfo) {
    return prepareInventoryDataFromOldDTO(data);
  }

  return prepareInventoryDataFromNewDTO(data);
};
