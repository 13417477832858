import { getRoles, createRole, updateRole, deleteRole } from '../../api/roles';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_ROLES_VALUE = 'CHANGE_ROLES_VALUE';
export const CHANGE_ROLES_SEVERAL_VALUES = 'CHANGE_ROLES_SEVERAL_VALUES';

export const RESET_ROLES_STORE = 'RESET_ROLES_STORE';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const getRolesAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ROLES });
      try {
        const response = await getRoles(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess && actions.onSuccess(results);
          dispatch({ type: GET_ROLES_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions.onError && actions.onError(error.message);
          dispatch({ type: GET_ROLES_ERROR });
        }
      }
    };
  };
})();

export const createRoleAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_ROLE });
    try {
      const response = await createRole(params);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: CREATE_ROLE_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: CREATE_ROLE_ERROR });
    }
  };
};

export const updateRoleAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_ROLE });
    try {
      const response = await updateRole(uuid, params);

      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: UPDATE_ROLE_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: UPDATE_ROLE_ERROR });
    }
  };
};

export const deleteRoleAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ROLE });
    try {
      const response = await deleteRole(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
        dispatch({ type: DELETE_ROLE_SUCCESS });
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
      dispatch({ type: DELETE_ROLE_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_ROLES_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_ROLES_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_ROLES_STORE,
});
