import { getMaterialsAnalytics } from '../../api/analytics';
import {
  getEntreeChutes,
  getExelExport,
  getEntreeChutesTotal,
  deleteEntreeChute,
} from '../../api/entreeChutes';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_ENTREE_CHUTES_EVENTS_VALUE = 'CHANGE_ENTREE_CHUTES_EVENTS_VALUE';
export const CHANGE_ENTREE_CHUTES_SEVERAL_VALUES = 'CHANGE_ENTREE_CHUTES_SEVERAL_VALUES';

export const RESET_ENTREE_CHUTES_EVENTS_STORE = 'RESET_ENTREE_CHUTES_EVENTS_STORE';

export const GET_ENTREE_CHUTES_EVENTS = 'GET_ENTREE_CHUTES_EVENTS';
export const GET_ENTREE_CHUTES_EVENTS_SUCCESS = 'GET_ENTREE_CHUTES_EVENTS_SUCCESS';
export const GET_ENTREE_CHUTES_EVENTS_ERROR = 'GET_ENTREE_CHUTES_EVENTS_ERROR';

export const DELETE_ENTREE_CHUTES_EVENT = 'DELETE_ENTREE_CHUTES_EVENT';
export const DELETE_ENTREE_CHUTES_EVENT_SUCCESS = 'DELETE_ENTREE_CHUTES_EVENT_SUCCESS';
export const DELETE_ENTREE_CHUTES_EVENT_ERROR = 'DELETE_ENTREE_CHUTES_EVENT_ERROR';

export const LOAD_ENTREE_CHUTES_FILE = 'LOAD_ENTREE_CHUTES_FILE';
export const LOAD_ENTREE_CHUTES_SUCCESS = 'LOAD_ENTREE_CHUTES_SUCCESS';
export const LOAD_ENTREE_CHUTES_ERROR = 'LOAD_ENTREE_CHUTES_ERROR';

export const GET_ENTREE_CHUTES_ANALYTIC = 'GET_ENTREE_CHUTES_ANALYTIC';
export const GET_ENTREE_CHUTES_ANALYTIC_SUCCESS = 'GET_ENTREE_CHUTES_ANALYTIC_SUCCESS';
export const GET_ENTREE_CHUTES_ANALYTIC_ERROR = 'GET_ENTREE_CHUTES_ANALYTIC_ERROR';

export const downloadEventsFile = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_ENTREE_CHUTES_FILE });
    try {
      const response = await getExelExport(params);
      const { success, results } = response;
      if (success) {
        response.code !== 202 && downloadFile(results, 'application/zip');
        dispatch({ type: LOAD_ENTREE_CHUTES_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_ENTREE_CHUTES_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getEntreeChutesAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ENTREE_CHUTES_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const [response, totalResponse] = await Promise.all([
          getMaterialsAnalytics(params, abortController.signal),
          getEntreeChutesTotal(
            {
              startTime: params.startEventTime,
              endTime: params.endEventTime,
              restaurantUuid: params.restaurantsUuid,
              materials: params.materials,
              packages: params.packages,
            },
            abortController.signal
          ),
        ]);

        const { success, results } = response;
        const { success: totalSuccess, results: total } = totalResponse;

        if (success && totalSuccess && !abortController.signal.aborted) {
          dispatch({ type: GET_ENTREE_CHUTES_ANALYTIC_SUCCESS, payload: { results, total }, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_ENTREE_CHUTES_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getEntreeChutesAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ENTREE_CHUTES_EVENTS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getEntreeChutes(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_ENTREE_CHUTES_EVENTS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_ENTREE_CHUTES_EVENTS_ERROR, params });
        }
      }
    };
  };
})();

export const deleteEntreeChuteAction = (uuid, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ENTREE_CHUTES_EVENT });
    try {
      const response = await deleteEntreeChute(uuid);
      const { success } = response;
      if (success) {
        actions?.onSuccess();
        dispatch({ type: DELETE_ENTREE_CHUTES_EVENT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
      dispatch({ type: DELETE_ENTREE_CHUTES_EVENT_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_ENTREE_CHUTES_EVENTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_ENTREE_CHUTES_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_ENTREE_CHUTES_EVENTS_STORE,
});
