import { combineReducers } from 'redux';

import appReducer from './appReducer';
import arucoReducer from './arucoValidationReducer';
import auditLogReducer from './auditLogReducer';
import authLogReducer from './authLogReducer';
import breadingReducer from './breadingReducer';
import camerasManagementReducer from './camerasManagementReducer';
import camerasRecordingsReducer from './camerasRecordingsReducer';
import camerasReducer from './camerasReducer';
import cateringInventoryReducer from './cateringReducer';
import cateringTimersReducer from './cateringTimersReducer';
import chickenContainersReducer from './chickenContainersReducer';
import chickenDashReducer from './chickenDashReducer';
import chickenEventsReducer from './chickenEventsReducer';
import chickenMaterialsReducer from './chickenMaterialsReducer';
import chickenPackagesReducer from './chickenPackagesReducer';
import chickenPerfReducer from './chickenPerfreducer';
import conveyorReducer from './conveyorReducer';
import devicesReducer from './devicesReducer';
import dumpPanInfReducer from './dumpPanInfReducer';
import dumpPotatoReducer from './dumpPotatoReducer';
import entreeChutesReducer from './entreeChutesReducer';
import emptyPanReducer from './emptyPanReducer';
import eventsAnalyticReducer from './eventsAnayticsReducer';
import fryChutesReducer from './fryChutesReducer';
import fryerManagerReducer from './fryerManagerReducer';
import fryerReducer from './fryerReducer';
import graphicsReducer from './graphicsReducer';
import identicalMarkersReducer from './identicalMarkersReducer';
import kitchenPlayerRecordingsReducer from './kitchenPlayerRecordingsReducer';
import kitchenPlayerReducer from './kitchenPlayerReducer';
import loginReducer from './loginReducer';
import miniPlayerReducer from './miniPlayerReducer';
import monitoringAlertsReducer from './monitoringAlertsReducer';
import notificationsReducer from './notificationsReducer';
import plugReducer from './plugReducer';
import potatoDashReducer from './potatoDashReducer';
import potatoEventsReducer from './potatoEventsReducer';
import potatoMaterialsReducer from './potatoMaterialsReducer';
import potatoPackagesReducer from './potatoPackagesReducer';
import policiesReducer from './policiesReducer';
import productionQueueReducer from './productionQueueReducer';
import prqReducer from './prqReducer';
import recommendationsReducer from './recommendationsReducer';
import reportsReducer from './reportsReducer';
import restaurantDevicesReducer from './restaurantDevicesReducer';
import restaurantModalReducer from './restaurantModalReducer';
import restaurantReducer from './restaurantReducer';
import restaurantTabletsReducer from './restaurantTabletsReducer';
import rolesReducer from './rolesReducer';
import serverManagementReducer from './serverManagementReducer';
import sessionsReducer from './sessionsReducer';
import stockOutReportsReducer from './stockOutReportsReducer';
import supportHistoryReducer from './supportHistoryReducer';
import tabletLogsReducer from './tabletLogsReducer';
import tabletSessionsReducer from './tabletSessionsReducer';
import tabletsReducer from './tabletsReducer';
import timersCycleAnalyticReducer from './timersCycleAnalyticReducer';
import timersCycleReducer from './timersCycleReducer';
import unusualEventsReducer from './unusualEventsReducer';
import unusualRecordingsReducer from './unusualRecordingsReducer';
import userSecurityReducer from './userSecurityReducer';
import userSessionsReducer from './userSessionsReducer';
import usersManageReducer from './usersManageReducer';
import wrongMarkerReducer from './wrongMarkerReducer';
import adjustmentReducer from './adjustment';

export const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  potatoEvents: potatoEventsReducer,
  chickenEvents: chickenEventsReducer,
  timers: timersCycleReducer,
  dumpInf: dumpPanInfReducer,
  fryChutes: fryChutesReducer,
  entreeChutes: entreeChutesReducer,
  prq: prqReducer,
  breading: breadingReducer,
  conveyor: conveyorReducer,
  fryer: fryerReducer,
  cateringTimers: cateringTimersReducer,
  unusualEvents: unusualEventsReducer,
  unusualRecordings: unusualRecordingsReducer,
  dumpPotato: dumpPotatoReducer,
  wrongMarker: wrongMarkerReducer,
  emptyPan: emptyPanReducer,
  devices: devicesReducer,
  auditLog: auditLogReducer,
  restaurants: restaurantReducer,
  cameras: camerasReducer,
  chickenDashboard: chickenDashReducer,
  potatoDashboard: potatoDashReducer,
  performances: chickenPerfReducer,
  restaurantDevices: restaurantDevicesReducer,
  kitchenPlayer: kitchenPlayerReducer,
  kitchenPlayerRecordings: kitchenPlayerRecordingsReducer,
  restaurantModal: restaurantModalReducer,
  chickenMaterials: chickenMaterialsReducer,
  chickenPackages: chickenPackagesReducer,
  chickenContainers: chickenContainersReducer,
  potatoMaterials: potatoMaterialsReducer,
  potatoPackages: potatoPackagesReducer,
  supportHistory: supportHistoryReducer,
  plugplay: plugReducer,
  aruco: arucoReducer,
  notifications: notificationsReducer,
  authLog: authLogReducer,
  graphics: graphicsReducer,
  timersCycleAnalytic: timersCycleAnalyticReducer,
  stockOutReports: stockOutReportsReducer,
  eventsAnalytic: eventsAnalyticReducer,
  monitoringAlerts: monitoringAlertsReducer,
  restaurantTablets: restaurantTabletsReducer,
  identicalMarkers: identicalMarkersReducer,
  sessions: sessionsReducer,
  tablets: tabletsReducer,
  tabletSessions: tabletSessionsReducer,
  usersManage: usersManageReducer,
  userSessions: userSessionsReducer,
  policies: policiesReducer,
  roles: rolesReducer,
  camerasManage: camerasManagementReducer,
  camerasRecordings: camerasRecordingsReducer,
  reports: reportsReducer,
  security: userSecurityReducer,
  miniPlayer: miniPlayerReducer,
  server: serverManagementReducer,
  tabletLogs: tabletLogsReducer,
  productionQueue: productionQueueReducer,
  catering: cateringInventoryReducer,
  fryerManager: fryerManagerReducer,
  adjustment: adjustmentReducer,
  recommendations: recommendationsReducer,
});

export default rootReducer;
