import * as types from '../actions/cateringAction';
import moment from 'moment';

export const addTaskDelayToBatches = (batches, timezone) => {
  if (!batches) return [];

  return batches.map((batch) => {
    if (batch.taskDelayStartTime) {
      const startTimeInMS = moment.tz(batch.taskDelayStartTime, timezone).format('x');
      let taskDelay = 0;

      if (batch.taskDelayEndTime) {
        const endTimeInMS = moment.tz(batch.taskDelayEndTime, timezone).format('x');
        const diff = endTimeInMS - startTimeInMS;
        taskDelay = diff > 0 ? Math.floor(diff) : 0;
      } else {
        const now = Date.now();
        taskDelay = Math.floor(now - startTimeInMS);
      }

      return { ...batch, taskDelay };
    }

    return batch;
  });
};

export const getMaterialDelay = (material) => {
  if (material.readyTime) {
    const [endHours, endMinutes] = material.endTime.split(':').map(Number);
    const [readyHours, readyMinutes] = material.readyTime.split('T')[1].split(':').map(Number);
    const endInSeconds = endHours * 3600 + endMinutes * 60;
    const readyInSeconds = readyHours * 3600 + readyMinutes * 60;

    return Math.max(0, readyInSeconds - endInSeconds);
  }

  return 0;
};

const updateCateringOrdersWithBatches = (orders, materialWithBatches = {}) => {
  return orders.map((order) => ({
    ...order,
    materials: order.materials.map((material) =>
      material.uuid === materialWithBatches.uuid
        ? {
            ...material,
            batches: materialWithBatches.batches
              ? addTaskDelayToBatches(materialWithBatches.batches, order.restaurant?.timezone)
              : material.batches,
            status: materialWithBatches.status ? materialWithBatches.status : material.status,
            readyTime: materialWithBatches.readyTime ? materialWithBatches.readyTime : material.readyTime,
            materialDelay: materialWithBatches.readyTime
              ? getMaterialDelay({ ...material, ...materialWithBatches })
              : material.materialDelay,
          }
        : {
            ...material,
            batches: addTaskDelayToBatches(material.batches, order.restaurant?.timezone),
            materialDelay: getMaterialDelay(material),
          }
    ),
  }));
};

const initialState = {
  restaurantUuid: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  limit: '20',
  loading: false,
  scrollLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  data: [],
  materialOptions: [],
};

const cateringInventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CATERING_INVENTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CATERING_INVENTORY_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_CATERING_INVENTORY:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_CATERING_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data: updateCateringOrdersWithBatches(
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content
        ),
      };

    case types.GET_CATERING_INVENTORY_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };

    case types.UPDATE_CATERING_INVENTORY_BATCHES:
      return {
        ...state,
        data: updateCateringOrdersWithBatches(state.data, action.payload),
      };

    case types.RESET_CATERING_INVENTORY_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default cateringInventoryReducer;
