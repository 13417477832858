import React, { useState } from 'react';
import cx from 'classnames';

import { ReactComponent as Search } from '../../../assets/img/icons/common/Search.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';

import './SearchField.scss';

export const SearchField = ({ name, placeholder, searchInput, maxLength, onChange }) => {
  const [isInputFocused, setInputFocused] = useState(false);

  const handleChange = (event) => {
    name ? onChange(name, event.target.value) : onChange(event.target.value);
  };

  const handleSearchClear = () => {
    name ? onChange(name, '') : onChange('');
  };

  return (
    <div className={cx('custom-search__container', { focused: isInputFocused })}>
      <Search />

      <input
        className="custom-search__input"
        type="text"
        placeholder={placeholder || 'Search'}
        maxLength={maxLength || 255}
        value={searchInput}
        onChange={handleChange}
        onFocus={() => setInputFocused(true)}
        onBlur={() => {
          setInputFocused(false);
        }}
      />

      {!!searchInput && (
        <button className="custom-search__clear-btn" onClick={handleSearchClear}>
          <Close />
        </button>
      )}
    </div>
  );
};
