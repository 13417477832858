import * as types from '../actions/rolesAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  sortBy: 'name',
  orderDirection: 'ASC',
  loading: false,
  search: '',
  restaurantUuid: null,
  data: [],
  currentRole: null,
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_ROLES_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_ROLES_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_ROLES:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_ROLES_SUCCESS: {
      const updatedCurrentRole = action.payload.content.find((role) => role.uuid === state.currentRole?.uuid);

      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
        currentRole: updatedCurrentRole || null,
      };
    }

    case types.GET_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        totalResults: 0,
        data: [],
      };

    case types.RESET_ROLES_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default rolesReducer;
