import request from '../utils/request';

export function getCateringOrders(params, signal) {
  return request({
    url: '/catering-orders',
    method: 'get',
    data: params,
    signal,
  });
}

export function createCateringOrder(params) {
  return request({
    url: '/catering-orders',
    method: 'post',
    data: params,
  });
}

export function updateCateringOrder(uuid, params) {
  return request({
    url: `/catering-orders/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteCateringOrder(uuid) {
  return request({
    url: `/catering-orders/${uuid}`,
    method: 'delete',
  });
}

export function finalizeCateringOrder(uuid, params) {
  return request({
    url: `/catering-orders/${uuid}/finalize`,
    method: 'put',
    data: params,
  });
}

// Timers API

export function getCateringTimers(params, signal) {
  return request({
    url: '/cateringTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getCateringTimersTotal(params, signal) {
  return request({
    url: '/cateringTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/cateringTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteCateringTimer(uuid) {
  return request({
    url: `/cateringTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
