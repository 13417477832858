import request from '../utils/request';

export function getPolicies(params, signal) {
  return request({
    url: `/policies`,
    method: 'get',
    data: params,
    signal,
  });
}

export function createPolicy(params) {
  return request({
    url: `/policies`,
    method: 'post',
    data: params,
  });
}

export function updatePolicy(uuid, params) {
  return request({
    url: `/policies/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deletePolicy(uuid) {
  return request({
    url: `/policies/${uuid}`,
    method: 'delete',
  });
}

export function getConstituents() {
  return request({
    url: `/roles/constituents`,
    method: 'get',
  });
}
