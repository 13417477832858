import cx from 'classnames';
import React, { useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { ReactComponent as Dots } from '../../../assets/img/icons/common/Dots.svg';

import './TableDropdown.scss';
import { useHandleOutsideClick } from '../../../hooks/useHandleOutsideClick';

export const TableDropdown = ({ options, small = false, disabled }) => {
  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);

  useHandleOutsideClick(show, () => setShow(false), dropdownRef);

  return (
    <Dropdown ref={dropdownRef} show={show} className={cx('table-dropdown', { small })}>
      <Dropdown.Toggle
        onClick={(event) => {
          event.stopPropagation();
          setShow((prev) => !prev);
        }}
        disabled={disabled}
      >
        <Dots />
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
        {options
          .filter((option) => !option.hidden)
          .map((option, index) => (
            <Dropdown.Item
              key={index}
              eventKey={index}
              disabled={option.disabled}
              style={{ color: option.color || '#1A1A1A' }}
              onClick={(event) => {
                event.stopPropagation();
                option.function && option.function(event);
                setShow(false);
              }}
            >
              {option.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
