import React from 'react';

import { ReactComponent as Edit } from '../../assets/img/icons/common/Edit.svg';
import { ReactComponent as Close } from '../../assets/img/icons/common/Close.svg';
import { ReactComponent as Dots } from '../../assets/img/icons/common/Dots.svg';

import { LargeIconButton, PageDropdown } from '../index';

import './SideItemDetails.scss';

export const SideItemDetails = ({ title, headerOptions, children, onEdit, onClose }) => {
  return (
    <div className="side-item-details__container">
      <header className="side-item-details__header">
        <div className="side-item-details__tools">
          {!!onEdit && <LargeIconButton Icon={Edit} onClick={onEdit} />}

          {!!headerOptions && <PageDropdown icon={Dots} options={headerOptions} />}

          <LargeIconButton Icon={Close} onClick={onClose} />
        </div>
      </header>

      <main className="side-item-details__main">
        <h2 className="side-item-details__title">{title}</h2>
        <div class="side-item-details__content">{children}</div>
      </main>
    </div>
  );
};
