import request from '../utils/request';

export function getPackages(params, signal) {
  return request({
    url: '/dictionaries/chickenPackages',
    method: 'get',
    data: params,
    signal,
  });
}

export function getPackage(id) {
  return request({
    url: `/dictionaries/chickenPackages/${id}`,
    method: 'get',
  });
}

export function createPackage(params) {
  return request({
    url: '/dictionaries/chickenPackages',
    method: 'post',
    data: params,
  });
}

export function updatePackage(uuid, params) {
  return request({
    url: `/dictionaries/chickenPackages/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deletePackage(uuid) {
  return request({
    url: `/dictionaries/chickenPackages/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}
