import request from '../utils/request';

export function getEntreeChutes(params, signal) {
  return request({
    url: '/entreeChutesTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getEntreeChutesTotal(params, signal) {
  return request({
    url: '/entreeChutesTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/entreeChutesTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteEntreeChute(uuid) {
  return request({
    url: `/entreeChutesTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
