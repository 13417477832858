import {
  blockUser,
  createUser,
  deleteUser,
  getUser,
  getUsers,
  resetUserPassword,
  sendInvitation,
  unblockUser,
  updateUser,
  updateUserNotification,
} from '../../api/users';
import { getRoles } from '../../api/roles';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_USERS_MANAGEMENT_VALUE = 'CHANGE_USERS_MANAGEMENT_VALUE';
export const CHANGE_USERS_MANAGEMENT_SEVERAL_VALUES = 'CHANGE_USERS_MANAGEMENT_SEVERAL_VALUES';
export const CHANGE_USERS_MANAGEMENT_EDIT_VALUE = 'CHANGE_USERS_MANAGEMENT_EDIT_VALUE';

export const RESET_USERS_MANAGEMENT_STORE = 'RESET_USERS_MANAGEMENT_STORE';
export const RESET_USERS_MANAGEMENT_EDIT_STORE = 'RESET_USERS_MANAGEMENT_EDIT_STORE';

export const CREATE_USER_MANAGEMENT = 'CREATE_USER_MANAGEMENT';
export const CREATE_USER_MANAGEMENT_SUCCESS = 'CREATE_USER_MANAGEMENT_SUCCESS';
export const CREATE_USER_MANAGEMENT_ERROR = 'CREATE_USER_MANAGEMENT_ERROR';

export const GET_USERS_MANAGEMENT = 'GET_USERS_MANAGEMENT';
export const GET_USERS_MANAGEMENT_SUCCESS = 'GET_USERS_MANAGEMENT_SUCCESS';
export const GET_USERS_MANAGEMENT_ERROR = 'GET_USERS_MANAGEMENT_ERROR';

export const GET_USER_MANAGEMENT = 'GET_USER_MANAGEMENT';
export const GET_USER_MANAGEMENT_SUCCESS = 'GET_USER_MANAGEMENT_SUCCESS';
export const GET_USER_MANAGEMENT_ERROR = 'GET_USER_MANAGEMENT_ERROR';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS';
export const GET_USER_ROLE_ERROR = 'GET_USER_ROLE_ERROR';

export const GET_USER_EDIT_MANAGEMENT = 'GET_USER_EDIT_MANAGEMENT';
export const GET_USER_EDIT_MANAGEMENT_SUCCESS = 'GET_USER_EDIT_MANAGEMENT_SUCCESS';
export const GET_USER_EDIT_MANAGEMENT_ERROR = 'GET_USER_EDIT_MANAGEMENT_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';

export const DELETE_USERS_MANAGEMENT = 'DELETE_USERS_MANAGEMENT';
export const DELETE_USERS_MANAGEMENT_SUCCESS = 'DELETE_USERS_MANAGEMENT_SUCCESS';
export const DELETE_USERS_MANAGEMENT_ERROR = 'DELETE_USERS_MANAGEMENT_ERROR';

export const getUsersAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_USERS_MANAGEMENT });
      try {
        const response = await getUsers(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions?.onSuccess([...results.content]);
          dispatch({ type: GET_USERS_MANAGEMENT_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions?.onError(error.message);
          dispatch({ type: GET_USERS_MANAGEMENT_ERROR, params });
        }
      }
    };
  };
})();

export const getUserAction = (id, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_MANAGEMENT });
    try {
      const response = await getUser(id);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_USER_MANAGEMENT_SUCCESS, payload: results });
        typeof setData === 'function' && setData(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_USER_MANAGEMENT_ERROR });
    }
  };
};

export const getUserRolesAction = (actions = null) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_ROLE });
    try {
      const response = await getRoles({});
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_USER_ROLE_SUCCESS, payload: results.content });
        actions?.onSuccess(results);
      }
    } catch (error) {
      dispatch({ type: GET_USER_ROLE_ERROR });
      console.log(error);
    }
  };
};

export const getUserEditAction = (id, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_EDIT_MANAGEMENT });
    try {
      const response = await getUser(id);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_USER_EDIT_MANAGEMENT_SUCCESS, payload: results });
        typeof setData === 'function' && setData(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_USER_EDIT_MANAGEMENT_ERROR });
    }
  };
};

export const createUserAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_USER_MANAGEMENT });
    try {
      const response = await createUser(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CREATE_USER_MANAGEMENT_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: CREATE_USER_MANAGEMENT_ERROR });
    }
  };
};

export const updateUserAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER });
    try {
      const response = await updateUser(params);

      const { success } = response;
      if (success) {
        actions.onSuccess();
      }
    } catch (e) {
      actions.onError(e.message);
    }
  };
};

export const updateUserNotificationAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    try {
      const response = await updateUserNotification(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_USER_NOTIFICATION });
        dispatch(changeValue('totalResults', 0));
      }
    } catch {
      actions.onError();
    }
  };
};

export const deleteUserAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_USERS_MANAGEMENT });
    try {
      const response = await deleteUser(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_USERS_MANAGEMENT_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: DELETE_USERS_MANAGEMENT_ERROR });
    }
  };
};

export const sendInvitationAction = (userUuid, actions = {}) => {
  return async () => {
    try {
      const response = await sendInvitation(userUuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
    }
  };
};

export const blockUserAction = (userUuid, params, actions = {}) => {
  return async () => {
    try {
      const response = await blockUser(userUuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
    }
  };
};

export const unblockUserAction = (userUuid, actions = {}) => {
  return async () => {
    try {
      const response = await unblockUser(userUuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
    }
  };
};

export const resetUserPasswordAction = (userUuid, actions = {}) => {
  return async () => {
    try {
      const response = await resetUserPassword(userUuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      actions.onError && actions.onError(e.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_USERS_MANAGEMENT_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeEditValue = (key, value) => ({
  type: CHANGE_USERS_MANAGEMENT_EDIT_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_USERS_MANAGEMENT_SEVERAL_VALUES,
  payload: value,
});

export const resetEditStore = () => ({
  type: RESET_USERS_MANAGEMENT_EDIT_STORE,
});

export const resetStore = () => ({
  type: RESET_USERS_MANAGEMENT_STORE,
});
