import * as types from '../actions/loginAction';

import { getAccessToken } from '../../utils/authorization';
import { getAllowedPages, getRolePermissions } from '../../utils/permissions';

const initialState = {
  user: {},
  isLoggedIn: !!getAccessToken() || false,
  isLoading: false,
  isBlocked: false,
  role: '',
  userType: null,
  restaurantsLoading: false,
  restaurants: [{ label: 'Global', value: 'select' }],
  restaurantOptions: [
    {
      label: 'Select Restaurant',
      value: 'select',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  ],
  restaurantsWithoutSelect: [],
  restaurantUuid: 'select',
  timezone: null,
  notification: false,
  verification: !!localStorage.getItem('multiFactorAuth'),
  verified: !localStorage.getItem('multiFactorAuth'),
  accessList: [],
  permissions: {},
  allowedPages: [],
  restaurant: null,
  loginInfo: {},
  previous: '/dashboard/chicken',
  changePasswordClicked: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return { ...state, isLoading: true };

    case types.LOGIN_USER_RESULT:
      return {
        ...state,
        isLoggedIn: true,
        loginInfo: action.payload,
        user: action.payload.user,
        verified: !action.payload.user.multiFactorAuthentication,
      };

    case types.SET_PASSWORD_RESULT:
      return {
        ...state,
        isLoading: false,
      };

    case types.LOGIN_USER_ERROR:
      return { ...state, isLoading: false };

    case types.GET_ME:
      return {
        ...state,
        isLoading: true,
      };

    case types.GET_ME_RESULT:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        userType: action.payload.type,
        accessList: action.payload.userRoleDto?.accessList,
        permissions: getRolePermissions(action.payload.userRoleDto),
        allowedPages: getAllowedPages(action.payload.userRoleDto),
        restaurant: action.payload.restaurantUuid,
        notification: action.payload.notification,
        verification:
          action.payload?.multiFactorAuthentication ||
          action.payload.user?.multiFactorAuthentication ||
          false,
      };

    case types.GET_ME_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case types.CHANGE_PASSWORD_RESULT:
      return {
        ...state,
        isLoggedIn: true,
        loginInfo: action.payload,
        user: action.payload.user,
      };

    case types.GET_LOGIN_RESTAURANTS:
      return {
        ...state,
        restaurantsLoading: true,
      };

    case types.GET_LOGIN_RESTAURANTS_RESULT:
      const isCurrentRestaurantExist = action.payload.restaurants.some(
        (restaurant) => restaurant.value === state.restaurantUuid
      );

      return {
        ...state,
        restaurantUuid: isCurrentRestaurantExist ? state.restaurantUuid : 'select',
        restaurantsLoading: false,
        restaurants: action.payload.restaurants,
        restaurantOptions: action.payload.restaurantOptions,
        restaurantsWithoutSelect: action.payload.restaurantOptions.slice(1),
      };

    case types.GET_LOGIN_RESTAURANTS_ERROR:
      return {
        ...state,
        restaurantsLoading: false,
      };

    case types.GET_LOGIN_RESTAURANT_RESULT:
      return {
        ...state,
        timezone: action.payload.timezone,
        restaurantOptions: action.payload.restaurantOptions,
        restaurantsWithoutSelect: action.payload.restaurantOptions.slice(1),
      };

    case types.CHANGE_LOGIN_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_EXPIRE_LOGIN_VALUE:
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          daysToPasswordExpiration: action.payload.value,
        },
      };

    case types.LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
      };

    case types.LOGOUT_USER_ERROR:
      return {
        ...state,
        isLoggedIn: !!getAccessToken() || false,
      };

    case types.BLOCK_USER:
      return {
        ...state,
        isBlocked: true,
      };

    case types.RESET_LOGIN_STORE:
      return {
        ...state,
        ...initialState,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default loginReducer;
