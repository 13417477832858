import * as types from '../actions/policiesAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  sortBy: 'name',
  orderDirection: 'ASC',
  loading: false,
  search: '',
  restaurantUuid: null,
  data: [],
  currentPolicy: null,
};

const policiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_POLICIES_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_POLICIES_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_POLICIES:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_POLICIES_SUCCESS: {
      const updatedCurrentPolicy = action.payload.content.find(
        (policy) => policy.uuid === state.currentPolicy?.uuid
      );

      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
        currentPolicy: updatedCurrentPolicy || null,
      };
    }

    case types.GET_POLICIES_ERROR:
      return {
        ...state,
        loading: false,
        totalResults: 0,
        data: [],
      };

    case types.RESET_POLICIES_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default policiesReducer;
